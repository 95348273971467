var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Aanpassingen bureau")
            ])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _vm.selected && _vm.selected.length
                    ? _c(
                        "MiButton",
                        { attrs: { color: "primary", small: "true" } },
                        [
                          _vm._v(
                            " (" +
                              _vm._s(_vm.selected.length) +
                              ") Logs verwerken "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("DataTable", {
                          ref: "reportLogsDatatable",
                          attrs: {
                            options: _vm.tableOptions,
                            visibility: _vm.visibility
                          },
                          model: {
                            value: _vm.selected,
                            callback: function($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }