import { formatDate } from '@/support/String';
import { ReportLog } from '@/models/ReportLog';
import { Component, Vue } from 'vue-property-decorator';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';

@Component<ExpertReportLogs>({})
export default class ExpertReportLogs extends Vue {
  public $pageTitle = 'Aanpassingen bureau';

  protected isLoading = false;

  protected selected = [];

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  }

  protected get tableOptions(): Object {
    return {
      model: new ReportLog().sort('created_at', 'DESC').filter({ type: 'expert_change' }),
      headers: [
        {
          text: 'Datum',
          value: 'created_at',
          transform: (created: string) => {
            if (! created) {
              return '';
            }
            return formatDate(created, 'dd-LL-yyyy HH:mm');
          },
          sortable: {
            key: 'created_at',
            order: 'DESC',
          },
          width: '20%',
        },
        {
          text: 'Bericht',
          value: 'text',
        },
      ],
      actions: [
        {
          type: 'success',
          label: 'view',
          icon: 'check_box',
          action: async (dataTable: DataTable, reportLog: ReportLog) => {
            await reportLog.update({
              is_processed: false,
            });

            this.refreshDataTable();
          },
          visible: (dataTable: DataTable, reportLog: ReportLog) => reportLog.is_processed,
          tooltip: 'Ongedaan maken',
        },
        {
          type: 'view',
          label: 'view',
          icon: 'check_box_outline_blank',
          action: async (dataTable: DataTable, reportLog: ReportLog) => {
            await reportLog.update({
              is_processed: true,
            });

            this.refreshDataTable();
          },
          visible: (dataTable: DataTable, reportLog: ReportLog) => ! reportLog.is_processed,
          tooltip: 'Verwerk',
        },
      ],
      filter: [],
    };
  }

  protected refreshDataTable() {
    document.dispatchEvent(
      new CustomEvent('datatable:hook', {
        detail: (dataTable: DataTable) => {
          dataTable.refresh();
        },
      }),
    );
  }
}
